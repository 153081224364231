<template>
  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_609)">
      <path
        d="M27.4397 14.3996H15.6797V15.5993H26.8676C26.5736 22.9298 20.914 28.7992 14 28.7992C11.1686 28.8051 8.41464 27.8093 6.16564 25.9663C3.91664 24.1234 2.29846 21.5364 1.56234 18.6071L13.7493 25.1359C13.8347 25.1815 13.9296 25.2031 14.0249 25.1985C14.1203 25.1938 14.213 25.1632 14.2942 25.1094C14.3754 25.0556 14.4424 24.9805 14.4889 24.8912C14.5354 24.8019 14.5598 24.7013 14.5599 24.5991V6.37042L26.9876 13.0281C27.0804 13.0779 27.1844 13.099 27.2877 13.0891C27.3911 13.0793 27.4899 13.0389 27.5732 12.9724C27.6564 12.9059 27.7207 12.8159 27.759 12.7126C27.7973 12.6092 27.808 12.4965 27.79 12.3869C27.2218 8.91242 25.5268 5.76443 23.0039 3.49813C20.481 1.23182 17.2923 -0.00723993 14 -0.000552908C6.2788 -0.000552908 0 6.72674 0 14.9994C0 15.1585 0.0589889 15.3111 0.16399 15.4236C0.268991 15.5361 0.411402 15.5993 0.559896 15.5993H12.3199V14.3996H1.13192C1.42637 7.06913 7.08552 1.19922 14 1.19922C16.8314 1.1935 19.5854 2.18945 21.8343 4.03247C24.0833 5.87549 25.7015 8.46246 26.4377 11.3918L14.2503 4.86258C14.1649 4.81687 14.07 4.7953 13.9747 4.79992C13.8794 4.80454 13.7867 4.8352 13.7055 4.88898C13.6243 4.94276 13.5574 5.01789 13.5109 5.10722C13.4644 5.19654 13.4401 5.29711 13.4401 5.39937V23.6285L1.0124 16.9708C0.919644 16.9209 0.815783 16.8997 0.712413 16.9094C0.609044 16.9192 0.510233 16.9595 0.427009 17.0259C0.343784 17.0923 0.279422 17.1822 0.241105 17.2855C0.202788 17.3888 0.192024 17.5015 0.210015 17.611C0.778193 21.0856 2.47318 24.2335 4.99608 26.4998C7.51897 28.7661 10.7077 30.0052 14 29.9985C21.7195 29.9985 28 23.2712 28 14.9985C27.9998 14.8395 27.9406 14.6871 27.8355 14.5748C27.7305 14.4625 27.5881 14.3994 27.4397 14.3996Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_609">
        <rect width="28" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'SolitanIcon',
};
</script>
