const roles = Object.freeze({
  OFFICE: 'OFFICE',
  TRADER: 'TRADER',
  FITTER: 'FITTER',
});

export const objRoles = Object.freeze({
  OFFICE: {
    value: 'OFFICE',
    text: 'Pracownik Biura',
  },
  TRADER: {
    value: 'TRADER',
    text: 'Handlowiec',
  },
  FITTER: {
    value: 'FITTER',
    text: 'Instalator',
  },
});

export default roles;
