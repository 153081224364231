import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pl from 'vuetify/lib/locale/pl';
import PeopleIcon from '../components/icons/PeopleIcon.vue';
import NavbarTriangleIcon from '../components/icons/NavbarTriangleIcon.vue';
import WizardIcon from '../components/icons/WizardIcon.vue';
import ShoppingIcon from '../components/icons/ShoppingIcon.vue';
import SquareIcon from '../components/icons/SquareIcon.vue';
import TriangleIcon from '../components/icons/TriangleIcon.vue';
import FloatingHelpIcon from '../components/icons/FloatingHelpIcon.vue';
import HomeIcon from '../components/icons/HomeIcon.vue';
import ListIcon from '../components/icons/ListIcon.vue';
import LogoutIcon from '../components/icons/LogoutIcon.vue';
import SettingsIcon from '../components/icons/SettingsIcon.vue';
import PhoneIcon from '../components/icons/PhoneIcon.vue';
import MailIcon from '../components/icons/MailIcon.vue';
import PdfIcon from '../components/icons/PdfIcon.vue';
import ArrowIcon from '../components/icons/ArrowIcon.vue';
import UsersIcon from '../components/icons/UsersIcon.vue';
import SolitanIcon from '../components/icons/SolitanIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FCCA03',
        secondary: '#848484',
        info: '#5C87C7',
        warning: '#FFC107',
        background: '#F5F5F5',
        font: '#36313c',
        'success-primary': '#84C75C',
        'success-secondary': 'F9FFF5',
        'background-secondary': '#fefefe',
        'error-primary': '#ff0000',
        'error-secondary': '#ffcbcb',
        'error-tertiary': '#dc5252',
        'tertiary-text': '7D7D7D',
        solitan: '#E94F2D',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      peopleIcon: {
        component: PeopleIcon,
      },
      arrowIcon: {
        component: ArrowIcon,
      },
      navbarTriangleIcon: {
        component: NavbarTriangleIcon,
      },
      wizardIcon: {
        component: WizardIcon,
      },
      shoppingIcon: {
        component: ShoppingIcon,
      },
      squareIcon: {
        component: SquareIcon,
      },
      triangleIcon: {
        component: TriangleIcon,
      },
      floatingHelpIcon: {
        component: FloatingHelpIcon,
      },
      homeIcon: {
        component: HomeIcon,
      },
      listIcon: {
        component: ListIcon,
      },
      logoutIcon: {
        component: LogoutIcon,
      },
      settingsIcon: {
        component: SettingsIcon,
      },
      phoneIcon: {
        component: PhoneIcon,
      },
      mailIcon: {
        component: MailIcon,
      },
      pdfIcon: {
        component: PdfIcon,
      },
      usersIcon: {
        component: UsersIcon,
      },
      solitanIcon: {
        component: SolitanIcon,
      },
    },
  },
  lang: {
    locales: { pl },
    current: 'pl',
  },
});
