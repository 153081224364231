import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/store';
import roles from '../static/roles';

Vue.use(VueRouter);

const allRoles = [roles.OFFICE, roles.TRADER, roles.FITTER];

const routes = [
  {
    path: '/',
    redirect: '/logowanie',
    name: 'Home',
    component: Home,
  },
  {
    path: '/logowanie',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/reset-email',
    name: 'PasswordReset',
    component: () => import('../views/ResetPassword/ResetPassword.vue'),
  },
  {
    path: '/reset-hasla/:token',
    name: 'SetPassword',
    component: () => import('../views/ResetPassword/SetPassword.vue'),
  },
  {
    path: '/haslo-zmienione',
    name: 'PasswordChanged',
    component: () => import('../views/ResetPassword/PasswordChanged.vue'),
  },
  {
    path: '/zmiana-hasla-email',
    name: 'PasswordMailSent',
    component: () => import('../views/ResetPassword/PasswordResetSent.vue'),
  },
  {
    path: '/edycja-konta',
    name: 'EditAccount',
    component: () => import('../views/User/EditAccount.vue'),
  },
  {
    path: '/aktywuj-konto/:userId',
    name: 'ActivateAccount',
    component: () => import('../views/Activation/ActivatePassword.vue'),
  },
  {
    path: '/wynik-aktywacji',
    name: 'ActivationEnd',
    component: () => import('../views/Activation/ActivationEndMessage.vue'),
  },
  {
    path: '/pracownicy',
    name: 'Employees',
    meta: {
      requiresAuth: true,
      authorize: roles.OFFICE,
    },
    redirect: { name: 'EmployeesList' },
    component: () => import('../views/Employees/EmployeeIndex.vue'),
    children: [
      {
        path: '',
        name: 'EmployeesList',
        meta: {
          requiresAuth: true,
          authorize: roles.OFFICE,
        },

        component: () => import('../views/Employees/EmployeesList.vue'),
      },
      {
        path: 'dodaj',
        name: 'AddEmployee',
        meta: {
          requiresAuth: true,
          authorize: roles.OFFICE,
        },

        component: () => import('../views/Employees/AddEmployee.vue'),
      },
      {
        path: ':userId',
        name: 'EditEmployee',
        meta: {
          requiresAuth: true,
          authorize: roles.OFFICE,
        },

        component: () => import('../views/Employees/EditEmployee.vue'),
      },
    ],
  },
  {
    path: '/klienci',
    name: 'Clients',
    meta: {
      requiresAuth: true,
      authorize: allRoles,
    },
    redirect: { name: 'ClientsList' },
    component: () => import('../views/Clients/ClientIndex.vue'),
    children: [
      {
        path: '',
        name: 'ClientsList',
        meta: {
          requiresAuth: true,
          authorize: allRoles,
        },

        component: () => import('../views/Clients/ClientsList.vue'),
      },
      {
        path: 'dodaj',
        name: 'AddClient',
        meta: {
          requiresAuth: true,
          authorize: allRoles,
        },
        component: () => import('../views/Clients/AddClient.vue'),
      },
      {
        path: ':clientId',
        name: 'ClientDetails',
        meta: {
          requiresAuth: true,
          authorize: allRoles,
        },
        component: () => import('../views/Clients/ClientDetails.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
];

const verifyUser = async (userData, to) => {
  if (userData && to.meta.authorize) {
    try {
      await store.dispatch(`auth/checkUser`);
    } catch (e) {
      await store.dispatch(`auth/logout`);
    }
  }
};

const redirectUnathorized = async (userData, to, next) => {
  if (to.meta.requiresAuth) {
    const currentRole = userData?.role;
    if (!to.meta.authorize.includes(currentRole)) {
      next({
        name: `Login`,
      });
    }
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  await verifyUser(userData, to);
  await redirectUnathorized(userData, to, next);
  next();
});

export default router;
