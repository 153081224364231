<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 2.66667C0 1.2 1.2 0 2.66667 0H13.3333V2.66667H2.66667V21.3333H13.3333V24H2.66667C1.2 24 0 22.8 0 21.3333V2.66667ZM18.9013 10.6667L15.52 7.28533L17.4053 5.4L24.0053 12L17.4053 18.6L15.52 16.7147L18.9013 13.3333H10.12V10.6667H18.9013Z"
      fill="#848484"
      fill-opacity="0.89"
    />
  </svg>
</template>
<script>
export default {
  name: 'LogoutIcon',
};
</script>
