<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19973 13.2001H2.39972V21.6C2.39972 22.9236 3.47611 24 4.7997 24H19.1996C20.5231 24 21.5995 22.9236 21.5995 21.6V13.2001H22.7995C23.0368 13.2001 23.2688 13.1297 23.4661 12.9978C23.6633 12.8659 23.8171 12.6785 23.9079 12.4593C23.9987 12.2401 24.0225 11.9988 23.9762 11.7661C23.9299 11.5333 23.8157 11.3196 23.6479 11.1517L12.848 0.351838C12.7367 0.240304 12.6044 0.15182 12.4589 0.0914472C12.3133 0.031075 12.1572 0 11.9996 0C11.842 0 11.686 0.031075 11.5404 0.0914472C11.3948 0.15182 11.2626 0.240304 11.1512 0.351838L0.351341 11.1517C0.183571 11.3196 0.0693246 11.5333 0.0230439 11.7661C-0.0232367 11.9988 0.000526736 12.2401 0.0913299 12.4593C0.182133 12.6785 0.335899 12.8659 0.533189 12.9978C0.730479 13.1297 0.962434 13.2001 1.19973 13.2001ZM9.59965 21.6V15.6001H14.3996V21.6H9.59965ZM11.9996 2.89701L19.1996 10.0969V15.6001L19.2008 21.6H16.7996V15.6001C16.7996 14.2765 15.7232 13.2001 14.3996 13.2001H9.59965C8.27606 13.2001 7.19967 14.2765 7.19967 15.6001V21.6H4.7997V10.0969L11.9996 2.89701Z"
      fill="#272727"
    />
  </svg>
</template>
<script>
export default {
  name: 'HomeIcon',
};
</script>
