import Vue from 'vue';
import axios from './plugins/axios';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies';
import Toasted from 'vue-toasted';
import i18n from './plugins/i18n';

Vue.config.productionTip = false;

Vue.use(axios);

Vue.use(Toasted, {
  duration: 5000,
  keepOnHover: true,
});

let optionsError = {
  type: 'error',
};

Vue.toasted.register(
  'error',
  (payload) => {
    if (!payload.message) {
      return 'Oops.. Coś poszło nie tak.';
    }

    return payload.message;
  },
  optionsError
);

let optionsSuccess = {
  type: 'success',
};

Vue.toasted.register(
  'success',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'Akcja przebiegła pomyślnie!';
    }

    // if there is a message show it with the message
    return payload.message;
  },
  optionsSuccess
);

new Vue({
  router,
  store,
  vuetify,
  VueCookies,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
