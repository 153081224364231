class QueryHandler {
  static buildQuery(url, params) {
    if (!params || Object.keys(params).length === 0) return url;

    const queryString = `${url}?`;
    const queries = [];

    for (let key of Object.keys(params)) {
      const param = `${key}=${params[key]}`;
      if (params[key] !== '') {
        queries.push(param);
      }
      if (param.includes('verified') && !params[key]) {
        queries.push(param);
      }
    }

    const finalQuery = queryString + queries.join('&');
    return finalQuery;
  }
}

export default QueryHandler;
