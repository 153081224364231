<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2396 24H21.0826C2.94418 22.9569 0.368792 7.65231 0.00879215 2.98154C-0.0202153 2.61838 0.0227201 2.25307 0.135138 1.90653C0.247556 1.56 0.427247 1.23905 0.663916 0.962079C0.900585 0.685108 1.18958 0.457558 1.51434 0.292467C1.8391 0.127376 2.19325 0.0279894 2.55648 8.65468e-07H7.64264C8.01238 -0.00035707 8.37372 0.110317 8.67984 0.317689C8.98596 0.52506 9.22276 0.819573 9.35956 1.16308L10.7626 4.61539C10.8977 4.95097 10.9313 5.31884 10.859 5.67332C10.7868 6.02779 10.6121 6.35324 10.3565 6.60923L8.39033 8.59385C8.69745 10.3392 9.53327 11.948 10.7847 13.2027C12.0361 14.4575 13.6428 15.2975 15.3873 15.6092L17.3903 13.6246C17.6502 13.3718 17.9787 13.2012 18.3349 13.1339C18.6911 13.0665 19.0592 13.1056 19.3934 13.2462L22.8734 14.64C23.2117 14.7811 23.5004 15.0198 23.7026 15.3255C23.9048 15.6313 24.0114 15.9904 24.0088 16.3569V21.2308C24.0088 21.9652 23.717 22.6696 23.1977 23.1889C22.6784 23.7082 21.974 24 21.2396 24ZM2.77802 1.84615C2.53321 1.84615 2.29842 1.94341 2.12531 2.11652C1.9522 2.28963 1.85495 2.52442 1.85495 2.76923V2.84308C2.27956 8.30769 5.00264 21.2308 21.1842 22.1538C21.3054 22.1613 21.427 22.1448 21.5419 22.1052C21.6567 22.0656 21.7627 22.0038 21.8536 21.9232C21.9445 21.8425 22.0186 21.7448 22.0716 21.6355C22.1246 21.5262 22.1556 21.4074 22.1626 21.2862V16.3569L18.6826 14.9631L16.0334 17.5938L15.5903 17.5385C7.55956 16.5323 6.47033 8.50154 6.47033 8.41846L6.41495 7.97539L9.03648 5.32616L7.65187 1.84615H2.77802Z"
      fill="#E94F2D"
    />
  </svg>
</template>
<script>
export default {
  name: 'PhoneIcon',
};
</script>
