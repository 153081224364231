import router from '../../router/router';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import roles from '../../static/roles';

const state = () => ({
  userData: null,
});

const setStateAndStorageForUser = (state, user) => {
  state.userData = { ...user };
  localStorage.setItem('userData', JSON.stringify(user));
};

const mutations = {
  setUser(state, user) {
    setStateAndStorageForUser(state, user);
  },
  setUserData(state, userData) {
    setStateAndStorageForUser(state, userData?.user);
  },
  login(state, userData) {
    setStateAndStorageForUser(state, userData?.user);
    router.push({ name: 'Clients' });
  },
  logout(state) {
    state.userData = null;
    localStorage.removeItem('userData');
    //TODO: RESET EVERYTHING IN STORE
    router.push({ name: 'Login' });
  },
};

const actions = {
  async login({ commit }, { email, password }) {
    const res = await AuthService.login(email, password);
    commit(`login`, res);
  },

  async checkUser({ commit }) {
    const res = await AuthService.checkUser();
    commit(`setUserData`, res);
  },

  logout({ commit }) {
    commit(`logout`);
  },

  async resetPassword(_, { token, newPassword, confirmationPassword }) {
    await AuthService.resetPassword(token, { newPassword, confirmationPassword });
  },

  async changePassword({ state, commit }, { newPassword, oldPassword }) {
    const res = await UserService.changePassword(state.userData._id, newPassword, oldPassword);
    commit(`setUser`, res);
  },

  async deleteAccount({ state, commit }) {
    await UserService.deleteAccount(state.userData._id);
    commit('logout');
  },

  async changeEmail({ state, commit }, email) {
    await UserService.update(state.userData?._id, { email });
    state.userData.email = email;
    commit('setUser', state.userData);
  },

  async changePhone({ state, commit }, phone) {
    await UserService.update(state.userData?._id, { phone });

    state.userData.phone = phone;
    commit('setUser', state.userData);
  },
};

const getters = {
  userData(state) {
    return state.userData;
  },

  isOffice(state) {
    return state.userData?.role === roles.OFFICE;
  },

  isTrader(state) {
    return state.userData?.role === roles.TRADER;
  },

  isFitter(state) {
    return state.userData?.role === roles.FITTER;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
