import ClientService from '../../services/ClientService';

const state = () => ({
  clients: [],
  client: {},
});

const mutations = {
  setClients(state, clients) {
    state.clients = clients;
  },
};

const actions = {
  async getAll({ state }, params = {}) {
    const res = await ClientService.getAll(params);
    state.clients = res.data;
    return res;
  },
  async addClient(_, { client }) {
    const res = await ClientService.createClient(client);
    return res;
  },

  async getClientById({ state }, { clientId }) {
    const res = await ClientService.getClientById(clientId);
    state.client = res.data;
    return res;
  },

  async assignEmployees({ dispatch }, { clientId, traderId, fitterId }) {
    const res = await ClientService.updateClient(clientId, {
      traderId,
      fitterId,
    });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async assignOrderNumber({ dispatch }, { clientId, orderNumber }) {
    const res = await ClientService.updateClient(clientId, { orderNumber });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async assignProvision({ dispatch }, { clientId, provision }) {
    const res = await ClientService.updateClient(clientId, { provision });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async assignAssemblyValue({ dispatch }, { clientId, assemblyValue }) {
    const res = await ClientService.updateClient(clientId, { assemblyValue });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async updateStatus({ dispatch }, { clientId, status }) {
    const res = await ClientService.updateClientStatus(clientId, { status });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async updateInstallationDate({ dispatch }, { clientId, installationDate }) {
    const res = await ClientService.updateClient(clientId, {
      installationDate,
    });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async updateSendEmail({ dispatch }, { clientId, sendEmail }) {
    await ClientService.updateClient(clientId, {
      sendEmail,
    });
    await dispatch('getClientById', { clientId });
  },

  async updateClient({ dispatch }, { clientId, client }) {
    const res = await ClientService.updateClient(clientId, { ...client });
    await dispatch('getClientById', { clientId });
    return res;
  },

  async uploadFile({ dispatch }, { clientId, type, file }) {
    const res = await ClientService.uploadFile(clientId, type, file);
    await dispatch('getClientById', { clientId });
    return res;
  },

  async deleteFile({ dispatch }, { clientId, uuid }) {
    const res = await ClientService.deleteFile(clientId, uuid);
    await dispatch('getClientById', { clientId });
    return res;
  },
};

const getters = {
  clients(state) {
    return state.clients || [];
  },
  client(state) {
    return state.client || {};
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
