<template>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#272727">
    <g>
      <title>Layer 1</title>
      <path
        id="svg_1"
        d="m11.84882,11.81628c2.4,0 4.2667,-1.9555 4.2667,-4.3555c0,-2.40002 -1.9555,-4.26668 -4.3555,-4.26668c-2.4,0 -4.2667,1.95555 -4.2667,4.26668c0,2.4 1.9555,4.3555 4.3555,4.3555zm-0.0888,-6.46951c0.0888,0 -0.34858,-0.18745 0.62483,0.12497c0.97341,0.31241 1.70294,0.6557 1.70294,2.07795c0,1.4222 -0.96805,2.17649 -2.39025,2.17649c-1.4223,0 -2.30145,-0.84319 -2.30145,-2.17649c0,-1.3333 1.03053,-2.20291 2.36393,-2.20291z"
        fill="#272727"
      />
      <path
        stroke-width="2"
        id="svg_2"
        d="m23.87669,12.00083c-1.76807,-1.5111 -4.66463,-2.40422 -7.08407,-2.31532l-0.8694,0.43738c-0.18613,0.7111 -0.71527,1.45827 -1.08743,1.99157c0.5583,-0.0889 1.02363,-0.0889 1.58193,-0.0889c1.76807,-0.0888 3.53613,0.4445 4.93193,1.4223l0,6.4l2.48596,0l0.06248,-7.23607"
        fill="#272727"
      />
      <path
        id="svg_3"
        d="m15.80048,3.99669c0.4445,-1.06667 1.18904,-1.47503 2.34464,-1.03059c1.0666,0.44445 1.47503,1.12655 1.03053,2.28211c-0.3555,0.8 -0.90557,1.02092 -1.70557,1.02092c-0.1778,0 -0.25705,0.12497 -0.43475,0.03608c0.0888,0.44449 -0.03617,1.26379 -0.03617,1.61939l0,0.5333c0.1778,0 0.3556,0.0889 0.5334,0.0889c2.2222,0 4,-1.7778 4,-3.91113c0,-2.22223 -1.7778,-4 -3.9111,-4c-1.4223,0 -2.6667,0.71111 -3.3778,1.95555c0.4444,0.26667 1.20131,0.87215 1.55681,1.40549z"
        fill="#272727"
      />
      <path
        id="svg_4"
        d="m8.66655,11.89104c-0.3555,-0.5333 -0.6222,-1.1555 -0.79998,-1.8666l-1.21098,-0.3749c-2.31111,-0.0889 -4.53333,0.77358 -6.22222,2.19578l-0.26666,0.57911l0.06248,7.17358l2.21515,-0.06248l0,-6.4c1.42223,-0.9778 3.02223,-1.5111 4.71111,-1.4222c0.53334,0 1.0667,0.0888 1.5111,0.1777z"
        fill="#272727"
      />
      <path
        id="svg_5"
        d="m6.09456,8.33291c0.17778,0 0.35555,0 0.53333,-0.0889l0,-0.5333c0,-0.4445 0,-0.8889 0.08889,-1.24449c-0.17778,0.08889 -0.19451,-0.22352 -0.6847,-0.22352c-0.49019,0 -1.69596,-0.66537 -1.69596,-1.82092c0,-1.15556 0.66537,-1.82093 1.82093,-1.82093c0.88888,0 1.56391,0.40837 1.91951,1.20837c0.3555,-0.44445 0.95138,-1.07634 1.39578,-1.4319c-1.1556,-1.86666 -3.55556,-2.48889 -5.42223,-1.33333c-1.86666,1.15555 -2.48889,3.55555 -1.33333,5.42222c0.71111,1.15559 1.95556,1.86669 3.37778,1.86669z"
        fill="#272727"
      />
      <path
        id="svg_6"
        d="m18.88849,15.67904l-0.1778,-0.2666c-1.7778,-1.9556 -4.2667,-3.1112 -6.9333,-3.0223c-2.6667,-0.0889 -5.2445,1.0667 -7.02225,3.0223l-0.17778,0.2666l0,6.7556c0,0.8 0.62222,1.5111 1.51113,1.5111l11.3778,0c0.8,0 1.5111,-0.7111 1.5111,-1.5111l0,-6.7556l-0.0889,0zm-2.34014,6.42642l-9.75588,-0.06248l0.06248,-5.67925c1.4223,-1.4222 2.87794,-1.63344 4.92244,-1.63344c1.9555,-0.0889 3.34876,0.3362 4.77096,1.7584l0,5.61677z"
        fill="#272727"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'UsersIcon',
};
</script>
