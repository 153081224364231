<template>
  <div>
    <nav class="navbar white relative main-navbar" :class="{ primary: $vuetify.breakpoint.sm }">
      <div class="white static-nav">
        <div class="inner-nav d-flex">
          <div class="d-flex align-center ml-2 ml-sm-0 logo-container" @click="toHome">
            <nav-logo-section class="pointer"></nav-logo-section>
          </div>
          <v-spacer></v-spacer>
          <nav-links-section v-if="userData" :userData="userData"></nav-links-section>
        </div>
      </div>
    </nav>
    <v-container class="global-spacing pb-0">
      <v-container class="pb-0">
        <div class="breadcrumps">
          <v-breadcrumbs
            v-if="matchedLinks.length > 1"
            class="pa-0 mb-0"
            :items="matchedLinks"
            divider="/"
          ></v-breadcrumbs>
        </div>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import NavLogoSection from './NavLogoSection.vue';
import NavLinksSection from './NavLinksSection.vue';
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import i18n from '../plugins/i18n';

export default {
  name: 'Navbar',
  components: { NavLogoSection, NavLinksSection },
  methods: {
    nextPath() {
      return !isEmpty(this.userData) ? 'ClientsList' : 'Login';
    },
    toHome() {
      const routeName = this.$route.name;
      if (this.nextPath() === routeName) return;
      if (routeName !== 'ClientsList' && routeName !== 'Login') {
        this.$router.push({ name: this.nextPath() });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    matchedLinks() {
      const filteredRoutes = this.$route.matched.filter((route) => {
        return route.parent?.redirect?.name !== route.name;
      });
      const routes = filteredRoutes.map((route) => {
        return {
          text: i18n.t(route.name),
          link: true,
          exact: true,
          disabled: false,
          to: { name: route.name },
        };
      });
      return routes;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';
.static-nav {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  position: relative;
  z-index: 10;
  padding-left: 1rem;
  @media screen and (max-width: 540px) {
    padding: 0;
  }
  & .inner-nav {
    max-width: 72.5rem;
    height: 4.5rem;
    margin: 0 auto;
  }
}

.main-navbar {
  overflow: hidden;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.logo-container {
  @media (max-width: 960px) {
    display: none !important;
  }
}
</style>
