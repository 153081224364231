import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/clients`;

axios.defaults.withCredentials = true;

class ClientService {
  static async getAll(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }
  static async createClient(client) {
    const res = await axios.post(`${url}`, {
      ...client,
    });
    return res.data.data;
  }
  static async getClientById(clientId) {
    const res = await axios.get(`${url}/${clientId}`);
    return res.data;
  }
  static async updateClient(clientId, client) {
    const res = await axios.put(`${url}/${clientId}`, {
      ...client,
    });
    return res.data;
  }

  static async updateClientStatus(clientId, client) {
    const res = await axios.put(`${url}/${clientId}/status`, {
      ...client,
    });
    return res.data;
  }

  static async uploadFile(clientId, type, file) {
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.put(`${url}/${clientId}/files?type=${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  }

  static async deleteFile(clientId, uuid) {
    const res = await axios.delete(`${url}/${clientId}/files?uuid=${uuid}`);
    return res.data;
  }
}

export default ClientService;
