import axios from 'axios';
import Vue from 'vue';
import VueI18n from '../plugins/i18n';
import store from '../store/store'; // path to your Vuex store
import router from '../router/router'; // path to your Vuex store

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && router.currentRoute.name !== 'Login') {
      store.commit('auth/logout');
      Vue.toasted.global.error({ message: 'Brak autoryzacji, zaloguj się na swoje konto' });
    }
    if (error.response.status === 403) {
      localStorage.removeItem('userData');
      Vue.toasted.global.error({ message: 'Nie masz uprawnień do wykonania tej akcji' });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.data?.errorCode) {
      Vue.toasted.global.error({ message: VueI18n.t(error.response.data.errorCode) });
    }
    return Promise.reject(error);
  }
);

export default axios;
