<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <navbar></navbar>
    <v-main>
      <v-container class="global-spacing">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
export default {
  name: 'App',
  components: { Navbar },
  computed: {
    theme() {
      return 'light';
    },
  },
};
</script>

<style lang="scss">
@import './scss/main.scss';
@import './scss/variables.scss';

.line {
  width: 100%;
  margin: auto;
  height: 0px;

  border: 1px solid #c4c4c4;
}

.stretch-height {
  height: 100% !important;
}
</style>
