<template>
  <svg width="94" height="95" viewBox="0 0 94 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M89.9315 82.4068V48.7818C89.9317 46.4425 89.4544 44.1277 88.5287 41.9792C87.603 39.8308 86.2484 37.894 84.5479 36.2874L52.8692 6.34887C51.2738 4.84046 49.1614 4 46.9657 4C44.7701 4 42.6577 4.84046 41.0623 6.34887L9.38361 36.2874C7.68313 37.894 6.32854 39.8308 5.40282 41.9792C4.47711 44.1277 3.99975 46.4425 4 48.7818V82.4068C4 84.6859 4.90535 86.8716 6.51687 88.4831C8.1284 90.0946 10.3141 91 12.5931 91H81.3383C83.6174 91 85.8031 90.0946 87.4146 88.4831C89.0261 86.8716 89.9315 84.6859 89.9315 82.4068Z"
      stroke="#E94F2D"
      stroke-width="8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'TriangleIcon',
};
</script>
