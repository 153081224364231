<template>
  <svg width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.749 0.5H2.24902C1.00637 0.5 -0.000976562 1.50734 -0.000976562 2.75V19.25C-0.000976562 20.4927 1.00637 21.5 2.24902 21.5H21.749C22.9917 21.5 23.999 20.4927 23.999 19.25V2.75C23.999 1.50734 22.9917 0.5 21.749 0.5ZM21.4678 19.25H2.53027C2.45568 19.25 2.38414 19.2204 2.3314 19.1676C2.27866 19.1149 2.24902 19.0433 2.24902 18.9688V3.03125C2.24902 2.95666 2.27866 2.88512 2.3314 2.83238C2.38414 2.77963 2.45568 2.75 2.53027 2.75H21.4678C21.5424 2.75 21.6139 2.77963 21.6666 2.83238C21.7194 2.88512 21.749 2.95666 21.749 3.03125V18.9688C21.749 19.0433 21.7194 19.1149 21.6666 19.1676C21.6139 19.2204 21.5424 19.25 21.4678 19.25ZM19.499 14.9375V16.0625C19.499 16.3731 19.2472 16.625 18.9365 16.625H9.56152C9.25088 16.625 8.99902 16.3731 8.99902 16.0625V14.9375C8.99902 14.6269 9.25088 14.375 9.56152 14.375H18.9365C19.2472 14.375 19.499 14.6269 19.499 14.9375ZM19.499 10.4375V11.5625C19.499 11.8731 19.2472 12.125 18.9365 12.125H9.56152C9.25088 12.125 8.99902 11.8731 8.99902 11.5625V10.4375C8.99902 10.1269 9.25088 9.875 9.56152 9.875H18.9365C19.2472 9.875 19.499 10.1269 19.499 10.4375ZM19.499 5.9375V7.0625C19.499 7.37314 19.2472 7.625 18.9365 7.625H9.56152C9.25088 7.625 8.99902 7.37314 8.99902 7.0625V5.9375C8.99902 5.62686 9.25088 5.375 9.56152 5.375H18.9365C19.2472 5.375 19.499 5.62686 19.499 5.9375ZM7.68652 6.5C7.68652 7.43197 6.93099 8.1875 5.99902 8.1875C5.06705 8.1875 4.31152 7.43197 4.31152 6.5C4.31152 5.56803 5.06705 4.8125 5.99902 4.8125C6.93099 4.8125 7.68652 5.56803 7.68652 6.5ZM7.68652 11C7.68652 11.932 6.93099 12.6875 5.99902 12.6875C5.06705 12.6875 4.31152 11.932 4.31152 11C4.31152 10.068 5.06705 9.3125 5.99902 9.3125C6.93099 9.3125 7.68652 10.068 7.68652 11ZM7.68652 15.5C7.68652 16.432 6.93099 17.1875 5.99902 17.1875C5.06705 17.1875 4.31152 16.432 4.31152 15.5C4.31152 14.568 5.06705 13.8125 5.99902 13.8125C6.93099 13.8125 7.68652 14.568 7.68652 15.5Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ListIcon',
};
</script>
