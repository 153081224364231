<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
    <path
      id="iconfinder_chevron-right_2561358_3_"
      data-name="iconfinder_chevron-right_2561358 (3)"
      d="M0,0,6,6,0,12"
      transform="translate(13.414 1.414) rotate(90)"
      class="arrow-icon-path"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowIcon',
};
</script>
<style lang="scss" scoped></style>
