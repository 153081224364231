<template>
  <v-container> </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({}),
};
</script>
