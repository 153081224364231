import UserService from '../../services/UserService';
import roles from '../../static/roles';
const state = () => ({
  users: null,
  employee: null,
  statistics: {},
});

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
};

const actions = {
  async getAll({ state }, params = {}) {
    const res = await UserService.getAll(params);
    state.users = res.data;
    return res;
  },
  async getTraders({ state }, params = {}) {
    const res = await UserService.getAll({ ...params, role: roles.TRADER });
    state.users = res.data;
    return res.data;
  },
  async getFitters({ state }, params = {}) {
    const res = await UserService.getAll({ ...params, role: roles.FITTER });
    state.users = res.data;
    return res.data;
  },
  async uploadFile(_, { userId, type, file }) {
    const res = await UserService.uploadFile(userId, type, file);
    return res;
  },
  async getUserById({ state }, { userId }) {
    const res = await UserService.getUser(userId);
    state.employee = res.data;
    return res.data;
  },
  async updateUser({ state }, { userId, user }) {
    const res = await UserService.update(userId, { ...user });
    state.employee = res.data;
    return res;
  },
  async getUserStatistics({ state }, { userId, fromDate, toDate }) {
    const statistics = await UserService.getUserStatistics(userId, fromDate, toDate);
    state.statistics = {
      ...statistics,
    };
  },
};

const getters = {
  users(state) {
    return state.users;
  },
  employee(state) {
    return state.employee;
  },
  statistics(state) {
    return state.statistics;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
