<template>
  <router-link :to="{ name: links }">
    <v-btn icon class="mr-6">
      <v-icon :class="isActive ? 'icon-active' : 'icon'">{{ icon }}</v-icon>
    </v-btn>
  </router-link>
</template>
<script>
export default {
  name: 'NavbarDropdownButton',
  props: ['links', 'icon'],
  computed: {
    isActive() {
      let isActive = true;
      const matchedComponents = this.$route.matched.map((route) => route.name);
      const dropdownComponents = this.links;
      isActive = matchedComponents.includes(dropdownComponents);
      return isActive;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';

.icon {
  opacity: 0.3;
}
.icon-active {
  opacity: 1;
}
</style>
