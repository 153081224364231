import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/static`;

axios.defaults.withCredentials = true;

class StaticService {
  static async getClientStatuses() {
    const res = await axios.get(`${url}/client-statuses`);
    return res.data;
  }

  static async getRoofTypes() {
    const res = await axios.get(`${url}/roof-types`);
    return res.data;
  }

  static async getInstallationTypes() {
    const res = await axios.get(`${url}/installation-types`);
    return res.data;
  }

  static async getClientFileTypes() {
    const res = await axios.get(`${url}/client-file-types`);
    return res.data;
  }

  static async getFileByRole(status) {
    const res = await axios.get(`${url}/role-file-types/${status}`);
    return res.data;
  }

  static async getRoles() {
    const res = await axios.get(`${url}/roles`);
    return res.data;
  }
}

export default StaticService;
