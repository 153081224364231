import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './auth/authModule';
import clientModule from './client/clientModules';
import staticModules from './static/staticModules';
import userModule from './user/userModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authModule,
    client: clientModule,
    static: staticModules,
    user: userModule,
  },
});
