import StaticService from '../../services/StaticService';

const state = () => ({
  clientsStatuses: null,
  roofTypes: null,
  installationTypes: null,
  clientFileTypes: null,
  roleFileTypes: null,
  roles: null,
});

const mutations = {
  setClients(state, clients) {
    state.clients = clients;
  },
};

const actions = {
  async getClientStatuses({ state }) {
    const res = await StaticService.getClientStatuses();
    state.clientsStatuses = res.data;

    return new Promise((resolve) => resolve(state.clientsStatuses));
  },

  async getRoofTypes({ state }) {
    if (state.roofTypes === null) {
      const res = await StaticService.getRoofTypes();
      state.roofTypes = res.data;
    }
    return new Promise((resolve) => resolve(state.roofTypes));
  },

  async getInstallationTypes({ state }) {
    if (state.installationTypes === null) {
      const res = await StaticService.getInstallationTypes();
      state.installationTypes = res.data;
    }
    return new Promise((resolve) => resolve(state.installationTypes));
  },
  async getClientFileTypes({ state }) {
    if (state.clientFileTypes === null) {
      const res = await StaticService.getClientFileTypes();
      state.clientFileTypes = res.data;
    }
    return new Promise((resolve) => resolve(state.clientFileTypes));
  },
  async getFileByRole({ state }, { status }) {
    const res = await StaticService.getFileByRole(status);
    state.roleFileTypes = res.data;
    return new Promise((resolve) => resolve(state.roleFileTypes));
  },
  async getRoles({ state }) {
    if (state.roles === null) {
      const res = await StaticService.getRoles();
      state.roles = res.data;
    }
    return new Promise((resolve) => resolve(state.roles));
  },
};

const getters = {
  clientsStatuses(state) {
    return state.clientsStatuses;
  },
  roofTypes(state) {
    return state.roofTypes;
  },
  installationTypes(state) {
    return state.installationTypes;
  },
  clientFileTypes(state) {
    return state.clientFileTypes;
  },
  roleFileTypes(state) {
    return state.roleFileTypes;
  },
  roles(state) {
    return state.roles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
