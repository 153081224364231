import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/users`;

axios.defaults.withCredentials = true;

class AuthService {
  static async login(email, password) {
    const requestBody = { email, password };
    const res = await axios.post(`${url}/login`, requestBody);
    return res.data.data;
  }

  static async register(
    firstName,
    lastName,
    email,
    password,
    nip,
    companyName,
    address,
    zipCode,
    city,
    phone,
    referralCode
  ) {
    const requestBody = {
      firstName,
      lastName,
      email,
      password,
      nip,
      companyName,
      address,
      zipCode,
      city,
      phone,
      referralCode,
    };
    const res = await axios.post(`${url}/register`, requestBody);
    return res.data.data;
  }

  static async checkUser() {
    const res = await axios.get(`${url}/checkUser`);
    return res.data.data;
  }

  static async registerUser(user) {
    const res = await axios.post(`${url}/register/employee`, { ...user });
    return res.data;
  }

  static async registerSysadmin(user) {
    const res = await axios.post(`${url}/register/sysadmin`, { ...user });
    return res.data;
  }

  static async activateUser(id, password) {
    const res = await axios.post(`${url}/activate-account/${id}`, { password });
    return res.data;
  }

  static async activateCompanyUser(id) {
    const res = await axios.get(`${url}/activate-company-account/${id}`);
    return res.data;
  }

  static async sendPasswordLink(email) {
    const res = await axios.post(`${url}/reset-password-link`, { email });
    return res.data;
  }

  static async resetPassword(token, { newPassword, confirmationPassword }) {
    const res = await axios.post(`${url}/reset-password/${token}`, {
      newPassword,
      confirmationPassword,
    });
    return res.data;
  }
}

export default AuthService;
