<template>
  <svg
    width="122"
    height="122"
    viewBox="0 0 122 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2165 50.02C20.4209 47.4722 21.5776 45.095 23.4559 43.3616C25.3343 41.6283 27.7967 40.6661 30.3526 40.6666H91.6475C94.2034 40.6661 96.6658 41.6283 98.5442 43.3616C100.423 45.095 101.579 47.4722 101.784 50.02L105.866 100.853C105.978 102.252 105.799 103.659 105.341 104.986C104.883 106.312 104.155 107.529 103.203 108.561C102.252 109.592 101.097 110.416 99.8114 110.979C98.526 111.542 97.1379 111.833 95.7345 111.833H26.2656C24.8622 111.833 23.4741 111.542 22.1887 110.979C20.9033 110.416 19.7484 109.592 18.7967 108.561C17.845 107.529 17.1172 106.312 16.659 104.986C16.2008 103.659 16.0223 102.252 16.1346 100.853L20.2165 50.02V50.02Z"
      stroke="#E94F2D"
      stroke-width="8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.3333 55.9166V30.5C81.3333 25.1072 79.1911 19.9354 75.3778 16.1221C71.5646 12.3089 66.3927 10.1666 61 10.1666C55.6073 10.1666 50.4354 12.3089 46.6222 16.1221C42.8089 19.9354 40.6667 25.1072 40.6667 30.5V55.9166"
      stroke="#E94F2D"
      stroke-width="8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'ShoppingIcon',
};
</script>
