<template>
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M172.333 108.528L108.557 172.305C106.121 174.744 103.229 176.679 100.045 178C96.8605 179.32 93.4472 180 90.0001 180C86.5529 180 83.1396 179.32 79.9554 178C76.7713 176.679 73.8787 174.744 71.4432 172.305L7.66667 108.528C2.75717 103.607 0 96.9386 0 89.9869C0 83.0353 2.75717 76.3677 7.66667 71.4461L71.4432 7.66956C76.3702 2.75813 83.0432 0 90.0001 0C96.9569 0 103.63 2.75813 108.557 7.66956L172.333 71.4461C177.243 76.3677 180 83.0353 180 89.9869C180 96.9386 177.243 103.607 172.333 108.528"
      fill="#E94F2D"
    />
    <path
      d="M65.0524 87.5789C65.0524 83.4472 63.4111 79.4846 60.4895 76.563C57.5678 73.6413 53.6053 72 49.4735 72C45.3417 72 41.3791 73.6413 38.4575 76.563C35.5359 79.4846 33.8945 83.4472 33.8945 87.5789"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30 96.2216V92.5683C30.0001 91.6998 30.2906 90.8563 30.8251 90.1718C31.3597 89.4872 32.1077 89.001 32.9503 88.7904L36.3387 87.9414C36.5109 87.8985 36.6905 87.8954 36.8641 87.9323C37.0377 87.9692 37.2005 88.0452 37.3404 88.1544C37.4802 88.2636 37.5933 88.4033 37.6711 88.5627C37.7489 88.7222 37.7894 88.8973 37.7895 89.0747V99.7132C37.7898 99.891 37.7495 100.067 37.6717 100.226C37.5939 100.386 37.4806 100.526 37.3405 100.636C37.2004 100.745 37.0372 100.821 36.8632 100.858C36.6893 100.895 36.5092 100.892 36.3367 100.849L32.9483 100.001C32.1061 99.7904 31.3585 99.3041 30.8243 98.6196C30.2902 97.9351 30 97.0918 30 96.2235V96.2216Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M68.9477 96.2216V92.5683C68.9475 91.6998 68.6571 90.8563 68.1226 90.1718C67.588 89.4872 66.84 89.001 65.9974 88.7904L62.609 87.9414C62.4368 87.8985 62.2571 87.8954 62.0836 87.9323C61.91 87.9692 61.7471 88.0452 61.6073 88.1544C61.4675 88.2636 61.3544 88.4033 61.2766 88.5627C61.1987 88.7222 61.1583 88.8973 61.1582 89.0747V99.7132C61.158 99.8908 61.1982 100.066 61.2759 100.226C61.3536 100.386 61.4667 100.525 61.6066 100.635C61.7464 100.744 61.9094 100.82 62.0831 100.858C62.2568 100.895 62.4367 100.891 62.609 100.849L65.9974 100.001C66.84 99.7908 67.588 99.3046 68.1226 98.6201C68.6571 97.9356 68.9475 97.092 68.9477 96.2235V96.2216Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M65.0523 101.21V102.184C65.0523 103.217 64.642 104.208 63.9116 104.938C63.1812 105.669 62.1905 106.079 61.1576 106.079H54.3418"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M52.395 109H46.5529C45.7782 109 45.0352 108.692 44.4874 108.144C43.9396 107.596 43.6318 106.853 43.6318 106.079C43.6318 105.304 43.9396 104.561 44.4874 104.013C45.0352 103.465 45.7782 103.158 46.5529 103.158H52.395C53.1697 103.158 53.9127 103.465 54.4605 104.013C55.0083 104.561 55.316 105.304 55.316 106.079C55.316 106.853 55.0083 107.596 54.4605 108.144C53.9127 108.692 53.1697 109 52.395 109Z"
      stroke="white"
      stroke-width="1.5"
    />
  </svg>
</template>
<script>
export default {
  name: 'FloatingHelpIcon',
};
</script>
<style lang="scss" scoped></style>
