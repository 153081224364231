import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/users`;

axios.defaults.withCredentials = true;

class UserService {
  static async getAll(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async update(id, user) {
    const res = await axios.put(`${url}/${id}`, { ...user });
    return res.data.data;
  }

  static async deleteAccount(id) {
    const res = await axios.delete(`${url}/${id}`);
    return res.data.data;
  }

  static async changePassword(id, newPassword, oldPassword) {
    const res = await axios.post(`${url}/change-password/${id}`, {
      newPassword,
      oldPassword,
    });
    return res.data.data;
  }

  static async getUser(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }

  static async createUser(user) {
    const res = await axios.post(`${url}/`, {
      ...user,
    });
    return res.data.data;
  }

  static async uploadFile(userId, type, file) {
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.put(`${url}/${userId}/files?type=${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  }

  static async getUserStatistics(userId, fromDate, toDate) {
    const res = await axios.get(
      `${url}/${userId}/statistics?fromDate=${fromDate}&toDate=${toDate}`
    );
    return res.data.data;
  }
}

export default UserService;
