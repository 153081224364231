<template>
  <div class="title-part white--text d-flex">
    <navbar-dropdown-button icon="$homeIcon" :links="homeLinks"></navbar-dropdown-button>
    <navbar-dropdown-button
      v-if="isOffice"
      icon="$usersIcon"
      :links="employeesLinks"
    ></navbar-dropdown-button>
    <navbar-dropdown-button icon="$settingsIcon" :links="settingsLinks"></navbar-dropdown-button>
    <span class="horizontal-divider mr-6"></span>
    <div class="company-data-container">
      <span class="name"> {{ userData.firstName }} {{ userData.lastName }} </span>
      <span class="company-name mr-6 ml-1 font-weight-regular"> ({{ $t(userData.role) }})</span>
    </div>
    <v-btn icon @click="logout" class="mr-4 mr-sm-0">
      <v-icon> $logoutIcon </v-icon>
    </v-btn>
    <v-icon class="nav-triangle" size="72px"> $navbarTriangleIcon </v-icon>
  </div>
</template>
<script>
import NavbarDropdownButton from './NavbarDropdownButton.vue';
export default {
  name: 'NavLinksSection',
  props: ['userData'],
  components: { NavbarDropdownButton },
  data() {
    return {
      homeLinks: 'Clients',
      employeesLinks: 'EmployeesList',
      settingsLinks: 'EditAccount',
    };
  },
  computed: {
    isNotMobile() {
      return !this.$vuetify.breakpoint.sm;
    },
    isOffice() {
      return this.userData.role === 'OFFICE';
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';
.title-part {
  background-color: $primary;
  align-items: center;
  position: relative;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;

  .horizontal-divider {
    display: inline-block;
    height: 75%;
    width: 1px;
    background-color: $secondary;
  }

  &::after {
    @media (min-width: 1130px) {
      content: '';
      width: 24.375rem;
      height: 4.5rem;
      background-color: $primary;
      overflow: hidden;
      position: absolute;
      right: -24.375rem;
    }
  }
  .name,
  .company-name {
    color: rgba(132, 132, 132, 0.89);
  }
  .nav-triangle {
    position: absolute;
    left: -3.3rem;
    opacity: 1;
  }

  .company-data-container {
    @media (max-width: 400px) {
      display: none;
    }
  }
}
</style>
